<template>
  <modal
    name="TeamBioPopup"
    transition="pop-out"
    :adaptive="true"
    v-if="bio"
  >
    <div class="teamBioPopup">
      <div class="teamBioPopup__inner">
        <div class="teamBioPopup__avatar">
          <div class="teamBioPopup__avatar-img" :style="`backgroundImage: url(${bio.avatar})`"></div>
        </div>
        <div class="teamBioPopup__header">
          <div class="teamBioPopup__name">{{bio.name}}</div>
          <div class="teamBioPopup__position">{{bio.position}}</div>
          <div class="teamBioPopup__wherefrom">{{bio.wherefrom}}</div>
        </div>
        <div class="teamBioPopup__content js-customScroll" data-outside="yes">
          <vue-scroll :ops="ops">
            <div v-html="bio.bio"></div>
          </vue-scroll>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import './TeamBioPopup.styl'

  export default {
    name: 'TeamBioPopup',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      bio: null,
    }),
    watch: {
      bio () {
        setTimeout(() => {
          this.$modal.show('TeamBioPopup')
        }, 0)
      },
    },
    methods: {
      show (id) {
        this.bio = this.$store.state.info.team1.find(item => item.id === id) || this.$store.state.info.team2.find(item => item.id === id)
      },
    },
    created () {
      this.$parent.$on('showTeamBio', this.show)
    },
  }
</script>
