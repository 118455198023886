<template>
  <modal class="black-popup" name="AboutPopup" transition="pop-out" :adaptive="true">
    <div class="aboutPopup" id="aboutPopup">
      <div class="aboutPopup__inner">
        <div class="aboutPopup__img">
          <img src="@/assets/img/logo.png" alt="logo"/>
        </div>
        <div class="aboutPopup__content js-customScroll" data-outside="yes">
          <vue-scroll :ops="ops">
            <article>
              <p><b>FLYJETS (FLY I Corporation) is a New York State Benefit Corporation with the goal of providing
                access
                to
                aviation worldwide.</b></p>
              <p>The FLYJETS system is an aviation marketplace, mapping engine and travel planning system. At its core,
                FLYJETS functions as a marketplace for things that move: the marketplace is an automated, end-to-end
                solution for charter aircraft and
                flights. FLYJETS also functions as a full-service, IATA-certified travel agency.</p>
              <p>As a benefit corporation, FLYJETS is committed to <b>doing well by doing good</b>, with all corporate
                duties and responsibilities directed to both shareholders and stakeholders.</p>
              <p>In keeping with its mission, FLYJETS has established its FLYJETS' FLYGreen Initiative ™, whereby with each
                flight booking, FLYJETS offers additional FLYRewards - over and above the amount ordinarily granted with
                each flight - to those users
                who elect to offset a part of their trip with carbon offsets, and/or fly with some amount of Sustainable
                Aviation Fuel (SAF), pending availability.</p>
              <p>The FLY Report is a digital newspaper and newsletter about aviation, space, eVTOLs,
                renewables and travel (by FLYJETS).</p>
              <p>Both the FLYJETS aviation marketplace and The FLY Report seek to provide clear
                information and transparency with respect to both business/charter aviation and the aviation industry at large.</p>
              <p>Operators, please reach out to us directly at FLY@flyjets.com if interested in listing your aircraft.
                The FLYJETS team builds specific custom dashboards and pricing algorithms for each of our Aircraft Provider partners.</p>
              <p>FLYJETS has also established The FLY Foundation, a non-profit with the mission of supporting aviation
                education, advancement in aviation and radio communication technology, and safe and sustainable
                flight.</p>
            </article>
          </vue-scroll>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import './AboutPopup.styl'

  export default {
    name: 'AboutPopup',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
  }
</script>
