<template>
  <div class="box box--infoBlock box--black">
    <div class="box__inner">
      <div class="infoBlock infoBlock--about">
        <div class="aboutInfo js-customScroll">
          <vue-scroll :ops="ops">
            <div class="aboutInfo__section">
              <div class="aboutInfo__title">About us</div>
              <div class="aboutInfo__content">
                <div class="aboutInfo__content-img">
                  <img src="@/assets/img/logo.png" alt="logo"/>
                </div>
                <div class="aboutInfo__content-text js-acc-item-mobile">
                  <div class="aboutInfo__content-text-preview js-acc-preview-mobile">
                    <article>
                      <p><b>FLYJETS (FLY I Corporation) is a New York State Benefit Corporation with the goal of
                        providing
                        access to aviation worldwide.</b> Our primary mission is to connect Flyers and Aircraft
                        Providers.
                      </p>
                      <p>The FLYJETS system is an aviation marketplace, mapping engine and travel planning system. At
                        its
                        core, FLYJETS functions as a marketplace for things that move: the marketplace is an automated,
                        end-to-end solution for charter aircraft
                        and flights. FLYJETS also functions as a full-service, IATA-certified travel agency.</p>
                    </article>
                  </div>
                  <div class="aboutInfo__content-text-full js-acc-content-mobile">
                    <article>
                      <div class="aboutInfo__content-text-full-firstPart js-firstPart">
                        <p><b>FLYJETS (FLY I Corporation) is a New York State Benefit Corporation with the goal of
                          providing
                          access to aviation worldwide.</b> Our primary mission is to connect Flyers and Aircraft
                          Providers.</p>
                        <p>The FLYJETS system is an aviation marketplace, mapping engine and travel planning system. At
                          its
                          core, FLYJETS functions as a marketplace for things that move: the marketplace is an
                          automated,
                          end-to-end solution for charter aircraft
                          and flights. FLYJETS also functions as a full-service, IATA-certified travel agency.</p>
                      </div>
                      <div class="aboutInfo__content-text-full-secondPart js-secondPart">
                        <p>As a benefit corporation, FLYJETS is committed to <b>doing well by doing good</b>, with all
                          corporate duties and responsibilities directed to both shareholders and stakeholders.</p>
                        <p>In keeping with its mission, FLYJETS has established its FLYJETS' FLYGreen Initiative ™, whereby
                          with
                          each flight booking, FLYJETS offers additional FLYRewards - over and above the amount
                          ordinarily
                          granted with each flight - to those
                          users who elect to offset a part of their trip with carbon offsets, and/or fly with some
                          amount
                          of
                          Sustainable Aviation Fuel (SAF), pending availability.</p>
                        <p>The FLY Report is a digital newspaper and newsletter about aviation, space, eVTOLs,
                          renewables and travel (by FLYJETS).</p>
                        <p>Both the FLYJETS aviation marketplace and The FLY Report seek to provide clear
                          information and transparency with respect to both business/charter aviation and the aviation industry at large.</p>
                        <p>Operators, please reach out to us directly at FLY@flyjets.com if interested in listing your aircraft.
                          The FLYJETS team builds specific custom dashboards and pricing algorithms for each of our Aircraft Provider partners.</p>
                        <p>FLYJETS has also established The FLY Foundation, a non-profit with the mission of supporting
                          aviation education, advancement in aviation and radio communication technology, and safe and
                          sustainable flight.</p>
                      </div>
                    </article>
                  </div>
                  <div
                    class="aboutInfo__content-link aboutInfo__content-link--mobile js-acc-title-mobile"
                    data-text="hide about us"
                    @click="toggleAboutMobile"
                  >
                    read more
                  </div>
                  <div
                    class="aboutInfo__content-link aboutInfo__content-link--desktop js-popup"
                    data-mod="white-popup out-close"
                    @click="$modal.show('AboutPopup')"
                  >
                    read more
                  </div>
                </div>
              </div>
            </div>
            <div class="aboutInfo__section">
              <div class="aboutInfo__title">Team</div>
              <div class="aboutInfo__subtitle">Click for bios</div>
              <div class="aboutInfo__team">
                <div
                  v-for="item of team1"
                  :key="item.id"
                  class="aboutInfo__team-item js-acc-item-mobile js-popup js-popup--desktop"
                  data-mod="white-popup out-close"
                  @click="showPopup(item.id)"
                >
                  <div class="aboutInfo__team-avatar" :style="`backgroundImage: url(${item.avatar})`">
                    <div class="aboutInfo__team-avatar-back"><span class="text">More</span></div>
                  </div>
                  <div class="aboutInfo__team-discription">
                    <div class="aboutInfo__team-name">{{item.name}}</div>
                    <div class="aboutInfo__team-position">{{item.position}}</div>
                    <div class="aboutInfo__team-bio js-bio-mobile" v-html="item.bio"></div>
                    <div
                      class="aboutInfo__team-link aboutInfo__team-link--mobile js-acc-title-mobile"
                      data-text="hide biography"
                      @click="togglePersonBio"
                    >read more
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="aboutInfo__section" style="background-color: #565563;">
              <div class="aboutInfo__team">
                <div
                  v-for="item of team2"
                  :key="item.id"
                  class="aboutInfo__team-item js-acc-item-mobile js-popup js-popup--desktop"
                  data-mod="white-popup out-close"
                  @click="showPopup(item.id)"
                >
                  <div class="aboutInfo__team-avatar" :style="`backgroundImage: url(${item.avatar})`">
                    <div class="aboutInfo__team-avatar-back"><span class="text">More</span></div>
                  </div>
                  <div class="aboutInfo__team-discription">
                    <div class="aboutInfo__team-name">{{item.name}}</div>
                    <div class="aboutInfo__team-position">{{item.position}}</div>
                    <div class="aboutInfo__team-bio js-bio-mobile" v-html="item.bio"></div>
                    <div
                      class="aboutInfo__team-link aboutInfo__team-link--mobile js-acc-title-mobile"
                      data-text="hide biography"
                      @click="togglePersonBio"
                    >read more
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
    <AboutPopup/>
    <TeamBioPopup/>
  </div>
</template>

<script>
  import AboutPopup from '@/components/common/modals/AboutPopup'
  import TeamBioPopup from '@/components/common/modals/TeamBioPopup'
  import './About.styl'

  export default {
    name: 'About',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      team1 () {
        const team1 = this.$store.state.info.team1;
        team1.forEach(elem => {
          switch (elem.id) {
            case 'JessicaFisherBio':
              elem.avatar = require('@/assets/img/infoBlock/about/Jessica.jpg');
              break;
            case 'YanaUgluntsBio':
              elem.avatar = require('@/assets/img/infoBlock/about/Yana.jpg');
              break;
          }
        });
        return team1;
      },
      team2 () {
        const team2 = this.$store.state.info.team2;
        team2.forEach(elem => {
          switch (elem.id) {
            case 'JaclynKolsbyBio':
              elem.avatar = require('@/assets/img/infoBlock/about/Jaclyn.jpg');
              break;
            case 'DanielArizaBio':
              elem.avatar = require('@/assets/img/infoBlock/about/Dani.jpg');
              break;
            case 'AndreiShemshuraBio':
              elem.avatar = require('@/assets/img/infoBlock/about/Andrei.jpg');
              break;
          }
        });
        return team2;
      },
    },
    methods: {
      showPopup (id) {
        if (!document.querySelector('html').classList.contains('d-mobile')) {
          this.$emit('showTeamBio', id)
        }
      },
      toggleAboutMobile (event) {
        const item = document.querySelector('.js-secondPart')
        this.$root.animations.slideToggle(item)

        if (event.currentTarget.innerHTML.trim() === 'read more') {
          event.currentTarget.innerHTML = 'hide about us'
        } else {
          event.currentTarget.innerHTML = 'read more'
        }
      },
      togglePersonBio (event) {
        const item = event.currentTarget.closest('.js-acc-item-mobile').querySelector('.js-bio-mobile')
        this.$root.animations.slideToggle(item)

        if (event.currentTarget.innerHTML.trim() === 'read more') {
          event.currentTarget.innerHTML = 'hide about us'
        } else {
          event.currentTarget.innerHTML = 'read more'
        }
      },
    },
    components: {
      AboutPopup,
      TeamBioPopup,
    },
    metaInfo: {
      title: 'About us',
      meta: [{
        name: 'robots', content: 'noindex',
      }],
    },
  }
</script>
